<!-- eslint-disable max-len -->
<template>
  <div class="page page--horno">

    <Hero :title="$t('oven.hero')" image="head-el-horno.jpg"></Hero>

    <div class="page__videos page__videos--horno">
      <h2>{{$t('oven.claim')}}</h2>

      <div class="page__videos-block page__videos-block--yellow page__videos-block--nopad">
        <div class="pad">
          <h3>{{$t('oven.subtitle-1')}}</h3>
          <h4>{{$t('oven.claim-1')}}</h4>
          <p>{{$t('oven.desc-1-1')}}</p>
          <p>{{$t('oven.desc-1-2')}}</p>
        </div>
        <div class="bg" style="background-image: url(/images/masa3.png)"></div>
      </div>

      <div class="page__iframe">
        <div class="page__iframe-wrap">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/xLsgSw-1aXQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div class="page__videos-block page__videos-block--black page__videos-block--nopad">
        <div class="bg" style="background-image: url(/images/el-horno-panes-secos.jpg)"></div>
        <div class="pad">
          <h3>{{$t('oven.subtitle-2')}}</h3>
          <h4>{{$t('oven.claim-2')}}</h4>
          <p>{{$t('oven.desc-2-1')}}</p>
          <p>{{$t('oven.desc-2-2')}}</p>
          <p><a href="https://tienda.panaderiajesus.com/categoria-producto/panes-secos/">{{$t('oven.desc-2-3')}}</a></p>
        </div>
      </div>

      <div class="page__videos-block page__videos-block--black page__videos-block--nopad">
        <div class="pad">
          <h3>{{$t('oven.subtitle-3')}}</h3>
          <h4>{{$t('oven.claim-3')}}</h4>
          <p>{{$t('oven.desc-3-1')}}</p>
          <p>{{$t('oven.desc-3-2')}}</p>
          <p><a href="https://tienda.panaderiajesus.com/categoria-producto/panes-frescos/">{{$t('oven.desc-3-3')}}</a></p>
        </div>
        <div class="bg" style="background-image: url(/images/el-horno-panes-frescos.jpg)"></div>
      </div>

      <div class="page__videos-block page__videos-block--black page__videos-block--nopad">
        <div class="bg" style="background-image: url(/images/dulcescaseros.png)"></div>
        <div class="pad">
          <h3>{{$t('oven.subtitle-4')}}</h3>
          <h4>{{$t('oven.claim-4')}}</h4>
          <p>{{$t('oven.desc-4-1')}}</p>
          <p>{{$t('oven.desc-4-2')}}</p>
          <p><a href="https://tienda.panaderiajesus.com/categoria-producto/dulces-caseros/">{{$t('oven.desc-4-3')}}</a></p>
        </div>
      </div>

    </div>

    <ShopClaim/>

  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import ShopClaim from '@/components/ShopClaim.vue';

export default {
  name: 'Horno',
  components: {
    Hero,
    ShopClaim,
  },
};
</script>

<style lang="scss">
.page--horno {
  background: #ECE3D0;
}
.page__videos--horno {
  h2 {
    color: black;
    margin-top: 80px;
  }
  .page__videos-block {
    h3 {
      font-weight: 100;
      font-size: 32px;
      margin: 0;
    }
    h4 {
      margin-top: 0;
      font-size: 24px;
      margin-bottom: 12px;
      font-weight: 900;
    }
  }
}
</style>
